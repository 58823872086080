import React, { FC, useState, useEffect, ChangeEvent, useRef } from 'react'
import { Input, Tag, Tooltip, Dropdown, Typography } from 'antd'

type TagInputProps = {
  className?: string
  style?: React.CSSProperties
  placeholder?: string
  value: string[]
  isReadOnly?: boolean
  onChange: (value: string[]) => void
  defaultTags?: string[]
  maxTags?: number
  regex?: RegExp
  errorHandling?: {
    errorMessage: string
    setErrorMessage: (value: string) => void
    handleError: (content: string) => void
  }
}

const arraysEqual = (arr1: string[], arr2: string[]): boolean => {
  if (arr1.length !== arr2.length) return false
  return arr1.every((value, index) => value === arr2[index])
}

const TagInput: FC<TagInputProps> = ({
  value = [],
  isReadOnly = false,
  onChange,
  placeholder,
  defaultTags = [],
  maxTags,
  regex,
  errorHandling,
  ...props
}) => {
  const [content, setContent] = useState<string>('')
  const [availableTags, setAvailableTags] = useState<string[]>(defaultTags)
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false)
  const [isDisabled, setIsDisabled] = useState<boolean | undefined>()

  const inputRef = useRef<any>(null)

  useEffect(() => {
    const filteredTags = defaultTags.filter((tag) => !value.includes(tag))
    if (!arraysEqual(availableTags, filteredTags)) {
      setAvailableTags(filteredTags)
    }
  }, [value, defaultTags])

  useEffect(() => {
    if (maxTags && value.length >= maxTags) {
      setIsDisabled(true)
    } else {
      if (isDisabled) {
        setIsDisabled(false)
      }
    }
  }, [value, maxTags])

  useEffect(() => {
    if (isDisabled === false) {
      inputRef.current?.focus()
    }
  }, [isDisabled])

  const handleDelete = (tag: string) => {
    const newArr = value.filter((i) => i !== tag)
    onChange(newArr)
    inputRef.current?.focus()
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    if (!value.length) errorHandling?.setErrorMessage('')
    setContent(value)
  }

  const validateContent = (content: string): boolean => {
    if (regex) {
      errorHandling?.handleError?.(content)
      return regex.test(content)
    }
    return true
  }

  const handleBlur = () => {
    if (content && validateContent(content)) {
      if (value.includes(content)) {
        return
      }
      onChange([...value, content])
      setContent('')
    }
  }

  const handleFocus = () => {
    setDropdownVisible(true)
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => e.key === 'Enter' && e.preventDefault()

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && content && validateContent(content)) {
      if (value.includes(content)) {
        return
      }
      onChange([...value, content])
      setContent('')
    }
  }

  const handleMenuClick = (e: any) => {
    const selectedTag = e.key
    onChange([...value, selectedTag])
    inputRef.current?.focus()
  }

  const handleDropdownVisibleChange = async () => {
    await new Promise((resolve) => setTimeout(resolve, 200))

    if (document.activeElement === inputRef.current?.input) {
      return
    }
    setDropdownVisible(false)
  }

  const menuItems = availableTags.map((tag) => ({
    key: tag,
    label: tag,
    style: {
      width: '100%'
    }
  }))

  const menuProps = {
    items: menuItems,
    onClick: handleMenuClick
  }

  return (
    <>
      <Dropdown
        overlayStyle={{
          maxHeight: '50vh',
          maxWidth: '25vw',
          overflow: 'auto'
        }}
        disabled={isDisabled}
        menu={menuProps}
        trigger={['click']}
        open={dropdownVisible}
        onOpenChange={handleDropdownVisibleChange}
      >
        <div
          onClick={() => inputRef.current?.focus()}
          className="ant-input ant-input-outlined ant-input-outlined py-2 px-3.5 text-base rounded-lg border-border border css-dev-only-do-not-override-ojsjer"
          {...props}
        >
          {value.map((item) =>
            item.length > 20 ? (
              <Tooltip title={item} key={item}>
                <Tag closable onClose={() => handleDelete(item)}>{`${item.slice(0, 20)}...`}</Tag>
              </Tooltip>
            ) : (
              <Tag closable key={item} onClose={() => handleDelete(item)}>
                {item}
              </Tag>
            )
          )}
          <Input
            readOnly={isReadOnly}
            disabled={isDisabled}
            ref={inputRef}
            variant="borderless"
            placeholder={!value.length ? placeholder : ''}
            className="!p-0 !w-auto"
            value={content}
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            onKeyDown={handleKeyPress}
            onKeyUp={handleKeyUp}
          />
        </div>
      </Dropdown>
      <Typography.Text className="text-red-600 text-sm">{errorHandling?.errorMessage}</Typography.Text>
    </>
  )
}

export default TagInput
